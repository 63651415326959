"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HtmlPreviewForm = void 0;
var tslib_1 = require("tslib");
var aurum_1 = require("@mdsi/aurum");
var react_1 = require("react");
var js_1 = require("@samsa/samsa-api/js");
var HtmlPreviewForm = function (_a) {
    var _b;
    var html = _a.html, type = _a.type, props = tslib_1.__rest(_a, ["html", "type"]);
    var _c = tslib_1.__read((0, react_1.useState)(null), 2), rendered = _c[0], setRendered = _c[1];
    var _d = tslib_1.__read((0, react_1.useState)({}), 2), templates = _d[0], setTemplates = _d[1];
    (0, react_1.useEffect)(function () {
        (function () { return tslib_1.__awaiter(void 0, void 0, void 0, function () {
            var list, templates;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, js_1.TicketTemplate.getAll({
                            size: 100,
                            deleted: false
                        })];
                    case 1:
                        list = _a.sent();
                        templates = {};
                        list.content.forEach(function (template) {
                            templates[template.type] = template.html;
                        });
                        setTemplates(templates);
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [type]);
    if (type === 'BASE') {
        html = html.replace('{{tickets}}', Object
            .entries(templates)
            .filter(function (_a) {
            var _b = tslib_1.__read(_a, 1), key = _b[0];
            return key !== 'BASE';
        })
            .map(function (_a) {
            var _b = tslib_1.__read(_a, 2), value = _b[1];
            return value;
        })
            .join(''));
    }
    else if (type) {
        html = (_b = templates === null || templates === void 0 ? void 0 : templates.BASE) === null || _b === void 0 ? void 0 : _b.replace('{{tickets}}', html);
    }
    return aurum_1.React.createElement(aurum_1.Sheet, { className: 'html', scrolly: true },
        aurum_1.React.createElement("form", { action: 'https://cdn.frisonaut.de/htmltopdf', method: 'post', target: 'pdf_iframe', style: { zIndex: 1 } },
            aurum_1.React.createElement("input", { type: 'hidden', name: 'html', value: html }),
            aurum_1.React.createElement(aurum_1.Button, { type: 'primary', disabled: !html || html === rendered, style: {
                    width: '100%'
                }, onClick: function (e) {
                    setRendered(html);
                    e.target.closest('form').submit();
                } }, "Vorschau laden")),
        aurum_1.React.createElement("iframe", { name: 'pdf_iframe', src: 'https://cdn.frisonaut.de/htmltopdf', style: {
                marginTop: '8px',
                width: '100%',
                height: 'calc(100% - 56px)',
                border: '1px solid #dadada',
                borderRadius: '4px',
                display: 'block'
            } }));
};
exports.HtmlPreviewForm = HtmlPreviewForm;
